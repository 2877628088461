<template>
  <v-row no-gutters>
    <v-col
      class="pa-0"
      cols="12"
    >
      <v-skeleton-loader
        :loading="getCategoryTreeAction"
        :style="{height: '100%', width: '100%'}"
        type="card-heading, image@1"
      >
        <v-row>
          <v-col>
            <base-ext-switch
              v-model="stepperData.all_nomenclature"
              class="switch__all-product"
              label="Использовать для всего ассортимента товаров."
            />
          </v-col>
        </v-row>
        <template
          v-if="!stepperData.all_nomenclature"
        >
          <v-row>
            <v-col>
              <treeselect
                v-model="selectedNomenclatureItems"
                :options="nomenclatureTree"
                :load-options="loadOptions"
                :auto-load-root-options="true"
                placeholder="Выберите категории и товары"
                value-format="object"
                loading-text="Загрузка..."
                auto-focus
                search-nested
                required
                show-count
                multiple
              >
                <template v-slot:option-label="{ node, shouldShowCount, count, countClassName }">
                  <v-icon v-if="node.raw.icon">
                    {{ node.raw.icon }}
                  </v-icon>
                  <span> {{ node.label }}</span>
                  <span
                    v-if="shouldShowCount && node.raw.children != null"
                    :class="countClassName"
                  >({{ count }})</span>
                </template>
                <template v-slot:value-label="{ node}">
                  <v-icon v-if="node.raw.icon">
                    {{ node.raw.icon }}
                  </v-icon>
                  {{ node.label }}
                </template>
              </treeselect>
            </v-col>
          </v-row>
          <v-row><v-col>ИЛИ</v-col></v-row>
          <v-row>
            <v-col>
              <nomenclature-segment-select
                v-model="stepperData.filter_nomenclature_segment_id"
                clearable
              />
            </v-col>
          </v-row>
        </template>

        <v-row>
          <v-col>
            <v-btn
              :disabled="!valid"
              color="primary"
              class="master-next-btn"
              @click="$emit('continue')"
            >
              <v-icon
                v-if="isEdit"
                class="icon"
                size="21"
              >
                $iconify_ion-checkmark-circle-outline
              </v-icon>
              <p class="body-m-semibold mb-0">
                {{ isEdit ? 'Сохранить' : 'Далее' }}
              </p>
              <v-icon
                v-if="!isEdit"
                :rigth="true"
              >
                $iconify_heroicons-outline-arrow-right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { deepClone } from '@/utils'
  import Treeselect, { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import NomenclatureSegmentSelect from '@/components/ModelSelect/NomenclatureSegmentSelect'

  // import Vue from 'vue'

  class NomenclatureCategory {
    constructor (data) {
      Object.assign(this, data)
      this.id = data.uuid
      this.label = data.name
      if (data.childs) this.childs = data.childs.map(child => new NomenclatureCategory(child))
      this.type = 'category'
      this._nomenclatures = null
      this.children = null
      this.icon = 'mdi-folder'
    }

    set nomenclatures (value) {
      this._nomenclatures = value
      this.children = this.childs.concat(value)
    }
  }

  class Nomenclature {
    constructor (data) {
      Object.assign(this, data)
      this.id = data.uuid
      this.label = data.name
      this.type = 'nomenclature'
      this.icon = null
    }
  }

  export default {
    components: {
      Treeselect, NomenclatureSegmentSelect,
    },
    props: {
      stepperData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    inject: ['isEdit'],
    data () {
      return {
        search: '',
        nomenclatureTree: [],
        selectedNomenclatureItems: [],
        activeNomenclaturesItems: [],
        getCategoryTreeAction: false,
        getNomenclatureListAction: false,
      }
    },
    computed: {
      ...mapGetters({
        categoryTree: 'company/nomen/categoryTree',
        nomenclatures: 'company/nomen/nomenclatures',
        nomenTotalCount: 'company/nomen/nomenTotalCount',
        nullCategory: 'company/nomen/defaultCategory',
        programId: 'programId',
      }),
      nomenclatureItemList () {
        return this.nomenclatureTree.reduce((accumulator, currentValue) => accumulator.concat(this.expandTreeNode(currentValue)), [])
      },
      selectedCategories () {
        return this.selectedNomenclatureItems.filter(item => item.type === 'category' && item.uuid !== this.nullCategory.uuid)
      },
      selectedNomenclatures () {
        return this.selectedNomenclatureItems.filter(item => item.type === 'nomenclature')
      },
      isNullCategorySelected () {
        return this.selectedNomenclatureItems.findIndex(item => item.type === 'category' && item.uuid === this.nullCategory.uuid) >= 0
      },
      selectedCategoriesUuids () {
        return this.selectedCategories.map(item => item.uuid)
      },
      selectedNomenclaturesUuids () {
        return this.selectedNomenclatures.map(item => item.uuid)
      },
      valid () {
        return (this.stepperData.all_nomenclature || (this.selectedNomenclatureItems && this.selectedNomenclatureItems.length > 0)
            || this.stepperData.filter_nomenclature_segment_id) && (this.stepperData.all_shops || this.stepperData.program_shop_ids.length > 0)
      },

    },
    watch: {
      // sync with stepperData
      selectedCategoriesUuids (value) {
        this.stepperData.nomenclature_category_uuids = value
      },
      selectedNomenclaturesUuids (value) {
        this.stepperData.nomenclature_uuids = value
      },
      isNullCategorySelected (value) {
        this.stepperData.null_category_selected = value
      },
      // ----------------------------------------------------------------

    },
    async mounted () {
      await this.init()
    },
    methods: {
      ...mapActions({
        getCategoryTree: 'company/nomen/getCategoryTree',
        getNomenCatList: 'company/nomen/getCategoryList',
        getNomenclatureList: 'company/nomen/getNomenclatureList',
      }),
      expandTreeNode (node) {
        const childsItems = node.childs
          ? node.childs.map(item => this.expandTreeNode(item)).reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
          : []
        return [node].concat(childsItems)
      },
      async init () {
        await this.fetchCategory()
        this.selectedNomenclatureItems = this.nomenclatureItemList.filter(
          item => this.$_.contains(this.stepperData.nomenclature_category_uuids, item.uuid) ||
            (item.isNullCategory && this.stepperData.null_category_selected),
        ).concat(this.stepperData.nomenclatures ? this.stepperData.nomenclatures.map(item => new Nomenclature(item)) : [])

        // if (this.stepperData.nomenclatures) {
        //   for (let index = 0; index < this.stepperData.nomenclatures.length; index++) {
        //     const element = this.stepperData.nomenclatures[index]
        //     const categoryIndex = this.nomenclatureItemList.findIndex(item => item.uuid === element.category_uuid)
        //     if (categoryIndex >= 0) {
        //       const parentCategory = this.nomenclatureItemList[categoryIndex]
        //       this.fetchNomenclatures(parentCategory)
        //     }
        //   }
        // }
      },

      async fetchCategory () {
        try {
          this.getCategoryTreeAction = true
          await this.getCategoryTree(this.stepperData.program_id || this.programId)
          const tree = deepClone(this.categoryTree)
          this.nomenclatureTree = tree.map(item => new NomenclatureCategory(item))
          return this.nomenclatureTree
        } catch (error) {
          console.error(error)
        } finally {
          this.getCategoryTreeAction = false
        }
      },

      async loadOptions ({ action, parentNode, callback }) {
        console.log('loadOptions', action, parentNode)
        if (action !== LOAD_CHILDREN_OPTIONS) return
        try {
          await this.fetchNomenclatures(parentNode)
          callback()
        } catch (error) {
          callback(new Error(error))
        } finally {

        }
      },
      async fetchNomenclatures (parentNode) {
        console.log('fetchNomenclatures', parentNode)
        try {
          this.getNomenclatureListAction = true
          const list = await this.getNomenclatureList({
            categoryUuid: parentNode.isNullCategory ? null : parentNode.uuid,
          })
          console.log()
          parentNode.nomenclatures = list.map(item => new Nomenclature(item))
        } catch (error) {
          console.error(error)
          throw error
        } finally {
          this.getNomenclatureListAction = false
        }
      },

    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.sass";

</style>
