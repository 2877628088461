var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-skeleton-loader',{style:({height: '100%', width: '100%'}),attrs:{"loading":_vm.getCategoryTreeAction,"type":"card-heading, image@1"}},[_c('v-row',[_c('v-col',[_c('base-ext-switch',{staticClass:"switch__all-product",attrs:{"label":"Использовать для всего ассортимента товаров."},model:{value:(_vm.stepperData.all_nomenclature),callback:function ($$v) {_vm.$set(_vm.stepperData, "all_nomenclature", $$v)},expression:"stepperData.all_nomenclature"}})],1)],1),(!_vm.stepperData.all_nomenclature)?[_c('v-row',[_c('v-col',[_c('treeselect',{attrs:{"options":_vm.nomenclatureTree,"load-options":_vm.loadOptions,"auto-load-root-options":true,"placeholder":"Выберите категории и товары","value-format":"object","loading-text":"Загрузка...","auto-focus":"","search-nested":"","required":"","show-count":"","multiple":""},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var countClassName = ref.countClassName;
return [(node.raw.icon)?_c('v-icon',[_vm._v(" "+_vm._s(node.raw.icon)+" ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(node.label))]),(shouldShowCount && node.raw.children != null)?_c('span',{class:countClassName},[_vm._v("("+_vm._s(count)+")")]):_vm._e()]}},{key:"value-label",fn:function(ref){
var node = ref.node;
return [(node.raw.icon)?_c('v-icon',[_vm._v(" "+_vm._s(node.raw.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(node.label)+" ")]}}],null,false,3138615263),model:{value:(_vm.selectedNomenclatureItems),callback:function ($$v) {_vm.selectedNomenclatureItems=$$v},expression:"selectedNomenclatureItems"}})],1)],1),_c('v-row',[_c('v-col',[_vm._v("ИЛИ")])],1),_c('v-row',[_c('v-col',[_c('nomenclature-segment-select',{attrs:{"clearable":""},model:{value:(_vm.stepperData.filter_nomenclature_segment_id),callback:function ($$v) {_vm.$set(_vm.stepperData, "filter_nomenclature_segment_id", $$v)},expression:"stepperData.filter_nomenclature_segment_id"}})],1)],1)]:_vm._e(),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"master-next-btn",attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":function($event){return _vm.$emit('continue')}}},[(_vm.isEdit)?_c('v-icon',{staticClass:"icon",attrs:{"size":"21"}},[_vm._v(" $iconify_ion-checkmark-circle-outline ")]):_vm._e(),_c('p',{staticClass:"body-m-semibold mb-0"},[_vm._v(" "+_vm._s(_vm.isEdit ? 'Сохранить' : 'Далее')+" ")]),(!_vm.isEdit)?_c('v-icon',{attrs:{"rigth":true}},[_vm._v(" $iconify_heroicons-outline-arrow-right ")]):_vm._e()],1)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }